#form{
  min-height: 130vh;
}
#form>section{
  min-height: 120vh;
}
#form>section>div{
height: fit-content !important;
}
#form input{
  background-color: #36383f;
  height: 40px;
  margin-bottom: 10px;
  border: none;
  color: aliceblue;
}
#form input:focus{
  outline: 1px solid green;

}
#remember{
height: 40px;
align-items: center;
}
#form2 form>div{
  min-height: 65px;
  align-items: center;
}
