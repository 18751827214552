#root{
  font-family: Arial, Helvetica, sans-serif;
}
/* *{
  padding: 0;margin: 0;
  box-sizing: border-box;
} */
a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
li{
  list-style-type: none;
}
