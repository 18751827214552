
@media screen and (max-width: 576px) {
  #items div{
    width: 90% !important;
    margin: 10px 0!important;
  }
}
@media screen and (min-width: 577px) {
  #items div{
    width: 90% !important;
    margin: 10px 0!important;

  }
}
@media screen and (min-width: 768px) {
  #items div{
    width: 280px !important;
    margin-right: 10px!important;
    margin-bottom: 10px!important;
  }
}
@media screen and (min-width: 992px) {
 
}
@media screen and (min-width: 1200px) {
 
}/*# sourceMappingURL=local.css.map */