.mainMenu,#mobile-menu{
 min-height: 100vh;
 /* border: 2px solid red; */
 background-color: #191c24;
 /* position: relative; */

}
.mainMenu nav{
  height: 100vh;
}
.mainMenu i {
color: gray;
}
#logo{
  height: 70px;
  align-items: center;
  display: flex;
}
#pro{
  height: 70px;
}
#pro span{
  font-size: 11px;
  color: gray;
}
#pro figure>img{
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.mainMenu nav li{
  margin-bottom: 5px;
  padding-left: 10px;
  width: 100%;
  min-height: 45px;
  color: gray;
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  border-radius: 30px 0 0 30px;
}
.mainMenu nav li a{
  color: gray;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}
.mainMenu #submenu{
  width: 100%;
}
.mainMenu nav li:hover,#mobile-menu>nav>ul>li:hover{
  background-color: #111319;
}
.mainMenu nav li a span:nth-of-type(1){
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color:#262930 ;
  margin-right: 10px;
}
.mainMenu nav li:nth-of-type(1) i ,#mobile-menu li:nth-of-type(1) i{
  color: rgb(229, 34, 229);
}
.mainMenu nav li:nth-of-type(2) i,#mobile-menu li:nth-of-type(2) i{
  color: gold;
}
.mainMenu nav li:nth-of-type(3) i,#mobile-menu li:nth-of-type(3) i{
  color: red;
}
.mainMenu nav li:nth-of-type(4) i,#mobile-menu li:nth-of-type(4) i{
  color: rgb(63, 63, 206);
}
#mobile-menu >h1{
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#mobile-menu>nav{
  margin:70px 0 0 0  ;
}
#mobile-menu{
  position: absolute;
  top: 0;left: -100%;
  width: 100%;
  z-index: 2000;
  transition: 1s;
}

#mobile-menu>nav>ul>li{
/* border: 1px solid red; */
width: 100%;
/* padding-left: 10px; */
margin: 10px 0;
/* border-radius: 20px 0 0 20px; */
}
#mobile-menu a{
  font-size: 25px;
}
#mobile-menu i{
  font-size: 25px;
}
#mobileLogo{
  text-shadow: -2px 2px 10px gray;
}

