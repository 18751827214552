#dashboard{
  /* margin-top: 70px; */
background-color: black;
}
#items{
 padding: 15px;
}
#items div{
  height: 120px;
  border-radius: 10px;
  background-color: #191c24;
  color: gray;
  padding: 10px;
}

#items div:nth-of-type(1)>span>i,
#items div:nth-of-type(2)>span>i,
#items div:nth-of-type(4)>span>i,
#items div:nth-of-type(1)>p>span:nth-of-type(1),
#items div:nth-of-type(2)>p>span:nth-of-type(1),
#items div:nth-of-type(4)>p>span:nth-of-type(1)
{
  color: green;
}
#items div:nth-of-type(1)>span>i,
#items div:nth-of-type(2)>span>i,
#items div:nth-of-type(4)>span>i{
font-size: 30px;}
#items div:nth-of-type(3)>span>i{
  font-size: 30px;
  color: red;
}
#items div:nth-of-type(3)>p>span:nth-of-type(1){
  color: red;
}
#projects{
  border-radius: 5px;
  padding: 20px ;
  background-color: #191c24;

}
#projects >div{
  height: 40px;
}
#projects>ul>li{
  padding: 5px 0;
  border-top:1px solid rgba(128, 128, 128, 0.503) ;
  border-bottom:1px solid rgba(128, 128, 128, 0.503) ;
}
#projects>ul>li>div:nth-of-type(1)>span>i{
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
}
#projects>ul>li:nth-of-type(1)>div:nth-of-type(1)>span>i{
  background-color: #2e65a5;
}
#projects>ul>li:nth-of-type(2)>div:nth-of-type(1)>span>i{
  background-color: green;
}
#projects>ul>li:nth-of-type(3)>div:nth-of-type(1)>span>i{
  background-color: rgb(79, 193, 231);
}
#projects>ul>li:nth-of-type(4)>div:nth-of-type(1)>span>i{
  background-color: tomato;
}
#projects>ul>li:nth-of-type(5)>div:nth-of-type(1)>span>i{
  background-color: gold;
}

#message{
  background-color: #191c24;
  padding: 15px;
  border-radius: 10px;
}
#message img{
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
#message li {
  padding: 10px 0;
  border-bottom: 1px solid rgb(164, 164, 164);
  border-top: 1px solid rgb(164, 164, 164);
}
#slider{
  border-radius: 10px;
  background-color: #191c24;
}
#worldmap{
  background-color: #191c24;
  border-radius: 10px;
}
.flag{
  width: 32px;
}
#table li{
  padding: 5px;
  border-bottom: 1px solid gray;
  align-items: center;
}
#progress{
  border-radius: 10px;
  background-color: #191c24;
}